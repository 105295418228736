import React from "react"
import Layout from "../components/Layout"
import big from "../assets/images/bgmain.jpg"
import styled from "styled-components"
import "bootstrap/dist/css/bootstrap.min.css"
import { Container, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

//para espacio de spacer hacer pequeno el background
const spacer = 20

//para agrandamiento de segundo porcentaje de background
const percentage = 200

const Franja = styled.div`
  background: black;
  height: 60px;
`

const TituloBox = styled.div`
  font-size: 38px;
  font-weight: 700;
  font-family: "Titillium Web";
  letter-spacing: -2px;
  color: white;
`
const MensajeBox = styled.div`
  font-size: 58px;
  font-weight: 700;
  font-family: "Titillium Web";
  letter-spacing: -2px;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
  @media (max-width: 768px) {
    font-size: 30px;
  }
`

const LeftBox = styled.div`
  font-size: 25px;
  font-weight: 500;
  font-family: "Titillium Web";
  letter-spacing: -2px;
  /* text-align: center; */
  padding-top: 50px;
  padding-bottom: 50px;
`

const RightBox = styled.div`
  font-size: 25px;
  font-weight: 500;
  font-family: "Titillium Web";
  /* letter-spacing: -2px; */
  /* text-align: center; */
  padding-top: 50px;
  padding-bottom: 50px;
`
const Boldie = styled.div`
  font-weight: bold;
`

const Politica = () => {
  return (
    <Layout sbiggo={big} spacer={spacer} percentage={percentage}>
      <Franja>
        <Container>
          <Row>
            <Col>
              <TituloBox>Política de Privacidad</TituloBox>
            </Col>
          </Row>
        </Container>
      </Franja>
      <Container>
        <Row>
          <Col>
            <MensajeBox>
              En Yaku contamos con estándares de alta calidad
            </MensajeBox>
          </Col>
        </Row>
      </Container>
      <StaticImage
        src="../assets/images/politica2.jpg"
        alt="web"
        aspectRatio={26 / 9}
      />
      <Container>
        <Row>
          <Col md={3} className="d-none d-lg-block">
            <LeftBox>
              <Boldie>Contáctenos: </Boldie>
              <br /> info@yaku.com.ec <br />
              +593999944405
            </LeftBox>
          </Col>
          <Col md={9} xs={12} className="d-lg-block">
            <RightBox>
              YAKU  le informa sobre su Política de Privacidad respecto del
              tratamiento y protección de los datos de carácter personal de los
              usuarios y clientes que puedan ser recabados por la navegación o
              contratación de servicios a través del sitio yaku.com.ec y redes
              sociales. <br />
              <br />
              En este sentido, Yaku garantiza el cumplimiento de la normativa
              vigente en materia de protección de datos personales, reflejada en
              la Ley N° 29733, Ley de Protección de Datos Personales. El uso de
              sitio Web implica la aceptación de esta Política de Privacidad así
              como las condiciones incluidas en el Aviso Legal. <br />
              <br />
              <Boldie>Principios aplicados en el tratamiento de datos </Boldie>
              <br />
              <br />
              En el tratamiento de sus datos personales, Yaku aplicará los
              siguientes principios que se ajustan a las exigencias de
              protección de datos: <br />
              <br />
              <ul>
                <li>
                  Principio de licitud, lealtad y transparencia: Yaku siempre
                  requerirá el consentimiento para el tratamiento de tus datos
                  personales que puede ser para uno o varios fines específicos
                  sobre los que le informará previamente con absoluta
                  transparencia.
                </li>
                <li>
                  Principio de minimización de datos: Yaku le solicitará solo
                  los datos estrictamente necesarios para el fin o los fines que
                  los solicita.
                </li>
                <li>
                  Principio de limitación del plazo de conservación: Los datos
                  se mantendrán durante el tiempo estrictamente necesario para
                  el fin o los fines del tratamiento.Yaku le informará del plazo
                  de conservación correspondiente según la finalidad. En el caso
                  de suscripciones, Yaku revisará periódicamente las listas y
                  eliminará aquellos registros inactivos durante un tiempo
                  considerable.
                </li>
                <li>
                  Principio de integridad y confidencialidad: Tus datos serán
                  tratados de tal manera que su seguridad, confidencialidad e
                  integridad esté garantizada. Debes saber que Yaku toma las
                  precauciones necesarias para evitar el acceso no autorizado o
                  uso indebido de los datos de sus usuarios por parte de
                  terceros.
                </li>
              </ul>
              <br />
              <br />
              <Boldie>Obtención de datos personales</Boldie>
              <br />
              <br />
              Para navegar por yaku.com.ec o sus redes sociales no es necesario
              que facilites ningún dato personal. Los casos en los que sí
              proporcionas tus datos personales son los siguientes:
              <br />
              <br />
              <ul>
                <li>
                  Al contactar a través de los formularios de contacto o enviar
                  un correo electrónico.
                </li>
                <li>
                  Al inscribirte en un formulario de suscripción o un boletín
                  que Yaku gestiona con MailChimp.
                </li>
              </ul>
              <br />
              <br />
              <Boldie>Tus derechos</Boldie>
              <br />
              <br />
              Yaku le informa que sobre tus datos personales tienes derecho a:
              <br />
              <ul>
                <li>Solicitar el acceso a los datos almacenados.</li>
                <li>Solicitar una rectificación o la cancelación.</li>
                <li>Solicitar la limitación de su tratamiento.</li>
                <li>Oponerte al tratamiento.</li>
                <li>Solicitar la portabilidad de tus datos. </li>
              </ul>
              <br />
              El ejercicio de estos derechos es personal y por tanto debe ser
              ejercido directamente por el interesado, solicitándolo
              directamente a Yaku, lo que significa que cualquier cliente,
              suscriptor o colaborador que haya facilitado sus datos en algún
              momento puede dirigirse Yaku y pedir información sobre los datos
              que tiene almacenados y cómo los ha obtenido, solicitar la
              rectificación de los mismos, solicitar la portabilidad de sus
              datos personales, oponerse al tratamiento, limitar su uso o
              solicitar la cancelación de esos datos en los ficheros del Yaku.
              <br />
              <br />
              Para ejercitar tus derechos de acceso, rectificación, cancelación,
              portabilidad y oposición tienes que enviar un correo electrónico
              a privacidad@yaku.com.ec junto con la prueba válida en derecho
              como una fotocopia de la cédula o equivalente.
              <br />
              <br />
              <Boldie>Finalidad del tratamiento de datos personales</Boldie>
              <br />
              <br />
              Cuando se conecta al sitio Web para mandar un correo a Yaku, se
              suscribe a su boletín o realizas una contratación, estás
              facilitando información de carácter personal de la que el
              responsable es Yaku. Esta información puede incluir datos de
              carácter personal como pueden ser tu dirección IP, nombre y
              apellidos, dirección física, dirección de correo electrónico,
              número de teléfono, y otra información. Al facilitar esta
              información, das tu consentimiento para que tu información sea
              recopilada, utilizada, gestionada y almacenada por el
              administrador del sitio web , sólo como se describe en el Aviso
              Legal y en la presente Política de Privacidad.
              <br />
              <br />
            </RightBox>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Politica
